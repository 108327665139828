import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, inject, input, model, OnDestroy, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EMPTY_CREW_NOTE_SHIP, EMPTY_CREW_NOTE_SHIP_EMPLOYEE, ICrewNoteShip, ICrewNoteShipSeaEmployee } from '../../models/crew-note';
import { HttpCrewNoteService } from '../../services/http-crew-note.service';
import { IShip } from '../../models/ship';
import { BaseCrudEntity, BaseEntity } from '@webapp-nx-repo/lib-shared-shell';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { combineLatest, map, of, Subscription, tap } from 'rxjs';
import { ErrorAlertComponent, HttpCrudService } from '@webapp-nx-repo/lib-shared-common';
import { NotaEquipaggioShipResolverT } from './nota-equipaggio-ship-resolver';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';

// Per orgni riga di "impiegato", tengo il BehaviorSubject per il loading 
// e la subscription per fare insert / delete on value change
// tengo i dati SRV della riga, in particolare l'ID per la delete SRV side
class IUIFormArrayData {
    savingEmployee = signal<boolean>(false);
    
    constructor(
        public data: ICrewNoteShipSeaEmployee,
        public fomChangeSubscription: Subscription,)
        {
        }
        destroy() {
            this.fomChangeSubscription.unsubscribe();
        }
        startSave() {
            this.savingEmployee.set(true);
        }
        stopSave() {
            this.savingEmployee.set(false);
        }
}

@Component({
	selector: 'lib-gp-nota-equipaggio-ship',
	imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatProgressBarModule,
    ],
	templateUrl: './nota-equipaggio-ship.component.html',
	styleUrl: './nota-equipaggio-ship.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotaEquipaggioShipComponent extends BaseEntity {
    private readonly changeDetectorRef = inject(ChangeDetectorRef);
    protected readonly confirmDialog = inject(MatDialog);
    private readonly httpCrewNoteService = inject(HttpCrewNoteService);
    private readonly fb = inject(FormBuilder);

    cn_ship_sea_employees_DataArray: IUIFormArrayData[] = [];
    formData = this.fb.group({
        cn_ship_sea_employees: this.fb.array<{
            id: number, // for tracking purposes
            board_qualification_id: number,
            sea_employee_id: number,
        }[]>([]),
    });
    get cn_ship_sea_employees_FormArray(): FormArray {
        return this.formData.controls.cn_ship_sea_employees as FormArray;
    }


    private data$ = this.route.data.pipe(
        map((data) => data['data'] as NotaEquipaggioShipResolverT),
        map(([crew_note_ship, sea_employees, board_qualifications]) => {

            // voglio ordinare i marittimi per cognome e nome
            sea_employees.sort((a, b) => {
                if(a.user_info!.surname === b.user_info!.surname) {
                    return a.user_info!.name < b.user_info!.name ? -1 : 1;
                }
                return a.user_info!.surname < b.user_info!.surname ? -1 : 1;
            });

            return {
                crew_note_ship,
                sea_employees,
                board_qualifications,
            };
        }),
        tap((data) => {
            if(data.crew_note_ship.crew_note_see_employees?.length === 0) {
                this.pushNewSeaEmployeeDataArrayAtPos({
                    ...EMPTY_CREW_NOTE_SHIP_EMPLOYEE,
                    crew_note_ship_id: data.crew_note_ship.id,
                });
            } else {
                data.crew_note_ship.crew_note_see_employees?.forEach(note_employee => this.pushNewSeaEmployeeDataArrayAtPos(note_employee) );
            }
        }),
    );
    data = toSignal(this.data$, {initialValue: {
        crew_note_ship: EMPTY_CREW_NOTE_SHIP,
        sea_employees: [],
        board_qualifications: [],
    }});


    protected override buildHeader(): void {
        this.menuService.header.set({
            leftCmd: this.getBackLeftCommandHeader(),
            title: 'M/n equipaggio',
            rightCmd: null,
        });
    }

    override _ngOnDestroy(): void {
        this.cn_ship_sea_employees_DataArray.forEach(x => x.destroy());
    }

    // Ho cn_ship_sea_employees_DataArray che tiene i dati SRV e i dati UI
    // e  cn_ship_sea_employees_FormArray che tiene i controlli della form

    pushNewSeaEmployeeDataArrayAtPos(cn_ship_sea_employee: ICrewNoteShipSeaEmployee): void {

        const employeeGroup = this.fb.group({
            id: this.fb.nonNullable.control<number>(cn_ship_sea_employee.id),
            board_qualification_id: this.fb.control<number|null>(cn_ship_sea_employee.board_qualification_id,    [Validators.required, Validators.min(1)]),
            sea_employee_id:        this.fb.control<number|null>(cn_ship_sea_employee.sea_employee_id,           [Validators.required, Validators.min(1)]),
        });
        const pos = this.cn_ship_sea_employees_FormArray.length;
        
        const subscription = combineLatest([
                of<number>(pos),
                employeeGroup.valueChanges,
            ]).subscribe({ 
                next: ([pos, value]) => {
                    const crewnote_ship_SeaEmployee_data = this.cn_ship_sea_employees_DataArray[pos].data;

                    // nothing changed then exit
                    if(crewnote_ship_SeaEmployee_data.board_qualification_id === value.board_qualification_id &&
                        crewnote_ship_SeaEmployee_data.sea_employee_id === value.sea_employee_id) {
                        return;
                    }

                    // remove ?
                    if(value.board_qualification_id === -1 && value.sea_employee_id === -1) {
                        this.removeEmployee(pos);
                    } 

                    // insert or update ?
                    else if(    value.board_qualification_id   && value.board_qualification_id > 0 && 
                                value.sea_employee_id          && value.sea_employee_id > 0 ) 
                    {
                        const item: ICrewNoteShipSeaEmployee = {
                            id: crewnote_ship_SeaEmployee_data.id,
                            crew_note_ship_id: crewnote_ship_SeaEmployee_data.crew_note_ship_id,
                            board_qualification_id: value.board_qualification_id,
                            sea_employee_id: value.sea_employee_id,
                        };                        
                        item.id === 0 ? this.insertEmployee(pos, item) : this.updateEmployee(pos, item);
                    }
                
                },
                error: (err: any) => {
                }
            });

        this.cn_ship_sea_employees_FormArray.push(
            employeeGroup
        );
        this.cn_ship_sea_employees_DataArray.push(
            new IUIFormArrayData(cn_ship_sea_employee, subscription)
        );
    }




    // pushEmployeeGroup è usata in $data per inizializzare i dati quindi this.data() non è disponibile
    // ma per il pulsante add posso usare this.data()
    addEmployeeGroup() {
        this.pushNewSeaEmployeeDataArrayAtPos({
            ...EMPTY_CREW_NOTE_SHIP_EMPLOYEE,
            crew_note_ship_id: this.data().crew_note_ship.id,
        });
    }
    

    removeEmployeeGroup(pos: number): void {
        this.cn_ship_sea_employees_DataArray[pos].destroy();
        this.cn_ship_sea_employees_DataArray.splice(pos, 1);
        this.cn_ship_sea_employees_FormArray.controls.splice(pos, 1);
        this.cn_ship_sea_employees_FormArray.updateValueAndValidity();
    }

    private insertEmployee(pos: number, item: ICrewNoteShipSeaEmployee) {        
        this.cn_ship_sea_employees_DataArray[pos].startSave();
        this.httpCrewNoteService.crew_note_ship_sea_employee
            .insert(item)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (srvItem: ICrewNoteShipSeaEmployee) => {
                    this.cn_ship_sea_employees_DataArray[pos].data = {
                        ...this.cn_ship_sea_employees_DataArray[pos].data,
                        ...srvItem,
                    };
                    (this.cn_ship_sea_employees_FormArray.at(pos) as FormGroup).controls['id'].setValue(srvItem.id);
                    this.cn_ship_sea_employees_DataArray[pos].stopSave();
                    this.onComplete('Marittimo inserito');
                },
                error: (err: any) => {
                    this.cn_ship_sea_employees_DataArray[pos].stopSave();
                    this.onError(err, 'update', 'errore nell\'inserimento');
                }
            });
    }

    private updateEmployee(pos: number, item: ICrewNoteShipSeaEmployee) {        
        this.cn_ship_sea_employees_DataArray[pos].startSave();
        this.httpCrewNoteService.crew_note_ship_sea_employee
            .update(item)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (srvItem: any) => {
                    this.cn_ship_sea_employees_DataArray[pos].data = {
                        ...this.cn_ship_sea_employees_DataArray[pos].data,
                        ...srvItem,
                    };
                    this.cn_ship_sea_employees_DataArray[pos].stopSave();
                    this.onComplete('Marittimo inserito');
                },
                error: (err: any) => {
                    this.cn_ship_sea_employees_DataArray[pos].stopSave();
                    this.onError(err, 'update', 'errore nell\'aggiornamento');
                }
            });
    }

    removeEmployee(pos: number) {
        
        if(this.cn_ship_sea_employees_DataArray[pos].data.id === 0) {
            this.removeEmployeeGroup(pos);
            this.changeDetectorRef.detectChanges();
        } else {
            this.cn_ship_sea_employees_DataArray[pos].startSave();
            this.httpCrewNoteService.crew_note_ship_sea_employee
                .delete(this.cn_ship_sea_employees_DataArray[pos].data.id)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: () => {
                        this.cn_ship_sea_employees_DataArray[pos].stopSave();
                        this.removeEmployeeGroup(pos);
                        this.changeDetectorRef.detectChanges();
                        this.onComplete('Marittimo eliminato');
                    },
                    error: (err: any) => {
                        this.cn_ship_sea_employees_DataArray[pos].stopSave();
                        this.onError(err, 'update', 'errore nella cancellazione');
                    }
                });
        }
    
    }

}
    