<div class="app-p4-mb8-mxauto-maxwscreenlg">
    
    <h2>
        {{ data().crew_note_ship.ship!.name }}
    </h2>
    

    <form [formGroup]="formData" autocomplete="off" novalidate class="mt-3">
        
        <div formArrayName="cn_ship_sea_employees" class="flex flex-col">

            @for(employeeGroup of cn_ship_sea_employees_FormArray.controls; track employeeGroup.value.id; let lastE = $last, j = $index) {

                <div class="border rounded p-4 bg-white mb-3">
                    <div [formGroupName]="j" class="flex flex-col md:flex-row gap-x-3">
                        <mat-form-field class="flex-grow">
                            <mat-label>Qualifica</mat-label>
                            <mat-select formControlName="board_qualification_id">
                                @for(qualification of data().board_qualifications; track qualification.id) {
                                    <mat-option [value]="qualification.id">
                                        {{ qualification.name }}
                                    </mat-option>
                                }
                                <mat-option [value]="-1">cancella riga</mat-option>
                            </mat-select>                        
                        </mat-form-field>

                        <mat-form-field class="flex-grow">
                            <mat-label>Marittimo</mat-label>
                            <mat-select formControlName="sea_employee_id">
                                @for(sea_employee of data().sea_employees; track sea_employee.id) {
                                    <mat-option [value]="sea_employee.id">                                    
                                        {{ sea_employee.user_info!.surname }}
                                        {{ sea_employee.user_info!.name }}                                
                                        ({{ sea_employee.matricola }} {{ sea_employee.compartimento }})
                                    </mat-option>
                                }
                                <mat-option [value]="-1">cancella riga</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    @if(cn_ship_sea_employees_DataArray[j].savingEmployee()) {
                        <mat-progress-bar mode="indeterminate" class="mb-3"></mat-progress-bar>
                    }
                </div>
            }

            
    
            <div class="mt-3">
                <button type="button" (click)="addEmployeeGroup()" mat-flat-button>
                    <mat-icon>add</mat-icon>
                    aggiungi marittimo
                </button>
            </div>
        </div>
    
    </form>
    
</div>